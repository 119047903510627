//@import "~getbasecore/src/components/atoms/Typography/core_typography.scss";
$body-font-size: 1.5rem;
$body-font-family: "Avenir";
$body-text-color: var(--color-text-1);
$body-selection-bg-color: #0653b6;
$body-selection-color: white;
$xs-adjustment: 20%; // 62.5 to match Desktop Size
$p-mb: var(--margin3);
$p-capitular-size: 6rem;
$lead-font-size: 2.2rem;
$small-font-size: 1.2rem;

//  --------------------------------------------------
//  Custom Fonts in html-start.html !
//
//  https://github.com/matthewgonzalez/fontplop/releases
// https://google-webfonts-helper.herokuapp.com/fonts/
//
//  --------------------------------------------------

//  --------------------------------------------------
//  Titulares
//  --------------------------------------------------

// @mixin generate_titles($title-name, $font-size, $line-height, $letter-spacing, $font-family, $font-weight,  $color, $mb, $bg:none, $pb:0, $border:0)

@include generate_titles("h1", 9.4rem, 9.4rem, -0.05rem, "Avenir", 700, var(--color-text-2), 6rem);
@include generate_titles("h2", 9.4rem, 9.4rem, -0.05rem, "Avenir", 100, var(--color-text-2), 4.4rem);
@include generate_titles("h3", 6rem, 6rem, -0.05rem, "Avenir", 700, var(--color-text-2), 3.5rem);
@include generate_titles("h4", 2.5rem, 2.5rem, -0.05rem, "Avenir", 300, var(--color-highlight), 1rem);
@include generate_titles("h5", 2rem, 2.7rem, -0.05rem, "Avenir", 300, var(--color-text-2), 2rem);
@include generate_titles("h6", 1.5rem, 1.5rem, -0.05rem, "Avenir", 300, var(--color-text-2), 1.5rem);

hgroup {
  margin-bottom: 100px;
  .h1,
  .h2 {
    margin-bottom: 0;
  }
}

// * + {
//   .h1,
//   .h2,
//   .h3,
//   .h4,
//   .h5,
//   .h6 {
//     margin-top: var(--margin2);
//   }
// }
//
// .h--border {
//   border-bottom: 1px solid currentcolor;
// }
//
// .h--highlight {
//   &:before {
//     content: "";
//     display: block;
//     height: 8px;
//     width: 40px;
//     background: #000;
//     margin-bottom: 20px;
//   }
// }
//
// //  --------------------------------------------------
// //  Ajuste de fuentes en responsive
// //  --------------------------------------------------
// html {
//   @media (max-width: var(--screen-xs-max)) {
//     font-size: $xs-adjustment !important;
//   }
//   @media (max-width: var(--screen-sm-max)) {
//     font-size: 52% !important;
//   }
// }
//
// //  --------------------------------------------------
// //  Global font attributes
// //  --------------------------------------------------
//
html > body {
  margin: 0;
  font-size: $body-font-size;
  font-family: $body-font-family;
  font-weight: 400;
  color: $body-text-color;
  letter-spacing: -0.2px;
  line-height: 140%;
}

.h1,
.h2,
.h3 {
  @media (max-width: $screen-xs-max) {
    font-size: 6rem;
    line-height: 6rem;
    -webkit-text-stroke-width: none;
    -webkit-text-stroke-color: transparent;
    //color: #fff
  }
}

.h3 {
  @media (max-width: $screen-xs-max) {
    font-size: 4rem;
    line-height: 4rem;
  }
}

//
// //  --------------------------------------------------
// //  Text selection
// //  --------------------------------------------------
// ::selection {
//   color: $body-selection-color;
//   background: $body-selection-bg-color;
// }
//
// //  --------------------------------------------------
// //  Text Tags
// //  --------------------------------------------------
//
// hr {
//   margin: var(--margin3) 0;
// }
//
// p {
//   margin-bottom: $p-mb;
//   line-height: 140%;
//   color: $body-text-color;
//   &.capitular {
//     &::first-letter {
//       float: left;
//       font-size: $p-capitular-size;
//       margin: 0 $body-font-size 1rem 0;
//       vertical-align: bottom;
//       margin-top: 10px;
//     }
//   }
// }
//
.lead {
  font-family: "Avenir";
  font-size: $lead-font-size;
  letter-spacing: 0;
  margin-bottom: var(--margin5);
  font-weight: 100;
}
//
// small {
//   font-size: $small-font-size;
//   display: inline-block;
//   margin-bottom: var(--margin3);
//   line-height: $small-font-size * 1.5;
//   color: var(--grey2);
// }
//
// strong,
// b {
//   font-weight: bold;
// }
//
// abbr {
//   border-bottom: 1px dotted;
// }
//
// em,
// i {
//   font-style: italic;
// }
//
// sub,
// sup {
//   font-size: 75%;
//   line-height: 0;
//   position: relative;
//   top: -0.5em;
// }
//
// sub {
//   top: auto;
//   bottom: -0.5em;
// }
//
// cite {
//   font-style: italic;
//   &:after,
//   &:before {
//     content: '"';
//   }
// }
//
// code {
//   background: #000;
//   color: white;
//   padding: 10px;
//   font-family: "Monaco";
//   display: block;
//   margin-bottom: var(--margin1);
// }
//
// mark {
// }
//
// del {
// }
//
// ins {
// }
//
// blockquote {
// }
//
// address {
// }
