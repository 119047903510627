.modal {
  --modal-transition: 0.5s;
  --modal-background: rgba(255, 255, 255, 0.1);
  --modal-background-filter: blur(10px);
  --modal-box-initial-position: -200vh;
  --modal-box-background: #fff;
  --modal-box-radius: 8px;
  --modal-box-padding: 20px;
  --modal-box-border: 1px solid #cbcbcb;
  --modal-header-mb: 24px;
  --modal-body-mb: 24px;
  --modal-footer-align: center;
  --modal-xs-width: 40vw;
  --modal-xs-height: 80vh;
  --modal-sm-width: 50vw;
}

.modal__close {
  line-height: inherit;
  color: #fff;
  font-size: 2rem;
  top: 0.6rem;
  right: 1rem;
}

.modal--windows {
  .modal__box {
    padding: 0;
    width: 75vw;
    overflow: hidden;
  }
  .modal__header {
    text-align: center;
    padding: 20px 0;
    background: #222;
    .h4 {
      color: #fff;
      margin-bottom: 0;
    }
  }
  .modal__body {
    padding: 0 20px;
    display: flex;
    @media (max-width: 767px) {
      display: block;
    }
    justify-content: space-around;
    max-height: 80vh;
    text-align: center;
    & > div {
      flex-basis: 50%;
    }
    @media (max-width: 767px) {
      & > div + div {
        margin-top: 50px;
      }
    }
    .btn-simple {
      margin-bottom: 0;
    }
  }
}
