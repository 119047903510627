.list {
  --list-mb: var(--margin3);
  --list-li-mb: var(--margin2);
  --list-bullets-size: 6px;
  --list-bullets-top: 5px;
  --list-bullets-color: var(--color-bg-primary);
  --desc-list-mb: var(--margin1);
  --desc-list-dt-mb: 5px;
  --desc-list-dd-mb: var(--margin1);
  --desc-list-dt-width: 160px;
  --ol-padding: 0px;
}

ol.list li::before {
  margin-right: 10px;
  position: static;
}

ol.list {
  text-align: center;
  margin-bottom: 0;
}
