.form--simple {
  --form-control-border: 1px solid var(--brown-grey);
  --form-mb: var(--margin3);
  --form-label-mb: var(--margin1);
  --form-control-border-radius: 0;
  --form-control-padding: 4px 10px;
  --form-control-box-shadow: none;
  --form-control-bg: white;
  --form-control-border-hover: 1px solid var(--blue1);
  --form-control-bg-hover: white;

  --form-help-padding: 12px 0;
  --form-help-text-size: 12px;

  --form-icon-size: 32px;
  --form-icon-right: 10px;
  --form-icon-top: 25px;
  --form-select-caret: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDE2IDEwIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzY2NiI+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0zLjkxNyA4Ljg4TDUuODc1IDcgMTEuNzUgMTIuNjQyIDE3LjYyNSA3LjAwMiAxOS41ODMgOC44ODIgMTEuNzUgMTYuNDAyeiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTU2OSAtMTQ5NikgdHJhbnNsYXRlKDExMiAxMDgxKSB0cmFuc2xhdGUoMjYyIDM5MSkgdHJhbnNsYXRlKDE5MS4xMzUgMTcpIi8+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K");
  --form-select-caret-size: 16px;
  --form-select-caret-position: right 12px center;
}
