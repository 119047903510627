.progress {
  --progress-height: 20px;
  --progress-mb: var(--margin3);
  --progress-bg: var(--color-border-1);
  --progress-color: white;
  --progress-font-size: 12px;
  --progress-transition: width 0.6s ease;
}

// @mixin generate-progress($name, $progress-color, $progress-bg, $progress-value-bg)
@include generate-progress(
  success,
  white,
  var(--progress-bg),
  var(--color-success)
);
