//
//
// Important!!! Only scss vars & mixins are allowed to be imported here.
// Dont use CSS Variables or regular CSS as this file is loaded in every component
//
//
@import "./utils/grid-layout/grid-layout.scss";
@import "./utils/mixins/mixins.scss";
@import "./utils/vars/vars.scss";
@import "./utils/animate/animate.scss";

//  --------------------------------------------------
//  Custom Fonts here ! Store them in src/assets/fonts
//
// Some examples here
//  https://github.com/matthewgonzalez/fontplop/releases
// https://google-webfonts-helper.herokuapp.com/fonts/
//
//  --------------------------------------------------
@font-face {
  font-family: "Avenir";
  src: url("fonts/avenir-light-webfont.woff2");
  font-weight: 100;
}
@font-face {
  font-family: "Avenir";
  src: url("fonts/avenir-roman-webfont.woff2");
  font-weight: 300;
}
@font-face {
  font-family: "Avenir";
  src: url("fonts/avenir-medium-webfont.woff2");
  font-weight: bolder;
}
