.section {
  --color-content: var(--color-highlight);
}
.section {
  // padding-bottom: 32px;

  &--dark {
    .section__content {
      padding-bottom: 100px;
    }
    svg {
      fill: #222;
    }
    .section__content {
      background: #222;
      &:before {
        background: #222;
      }
    }
  }

  &--early {
    .section__content {
      padding-top: 100px;
    }
    .h1,
    .h2,
    .h3,
    .h4,
    ol,
    li,
    p {
      color: var(--color-text-2) !important;
    }
    svg {
      fill: rgb(251, 225, 109);
    }
    .section__content {
      background: var(--color-bg-5);
      &:before {
        background: var(--color-bg-5);
      }
    }
  }

  &--igg {
    .section__content {
      padding-top: 100px;
    }
    .h1,
    .h2,
    .h3,
    .h4,
    ol,
    li,
    p {
      color: var(--white) !important;
    }
    svg {
      fill: rgb(251, 225, 109);
    }
    .section__content {
      background: var(--color-bg-6);
      &:before {
        background: var(--color-bg-6);
      }
    }
  }

  &__wave {
    fill: var(--color-content);
  }

  &__content {
    background: var(--color-content);
    color: #fff;
    position: relative;
    &:before {
      content: "";
      background: var(--color-content);
      height: 8px;
      width: 100%;
      display: block;
      position: absolute;
      top: -8px;
    }
  }

  .h1,
  .h2,
  .h3,
  .h4,
  p {
    color: #fff;
    text-align: center;
  }
}

.lamp {
  display: none !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 100vh;
  height: 100vh;
  width: 100vw;
  min-width: 100vw;
  z-index: -1;
}

.lava {
  filter: url("#goo");
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.blob {
  border-radius: 50%;
  background: var(--color-highlight);

  /* Other sample colors:
	Orange: #e8630a
	Pink: #EA3281
	Fuscia: #E71669
	Purple: #661BAC
	Yellow: #FDC600
	Kermit green: #75AC25
	Light blue: #3283B5
	Dark blue: #0A5D9A
	Gunmetal: #232F37
	BGLight: #1B2735
	BGDark: #090a0f
	Want more colors? check out https://coolors.co/ for inspiration.
	*/
  position: absolute;
}

.blob.top {
  border-radius: 50%;
  width: 100%;
  height: 4%;
  top: -3%;
  left: 0;
}
.blob.bottom {
  border-radius: 50%;
  width: 100%;
  height: 4%;
  bottom: -3%;
  left: 0;
}
.blob:nth-child(1) {
  width: 200px;
  height: 200px;
  left: 35%;
  bottom: -15%;

  animation: wobble 4s ease-in-out alternate infinite, blob-one ease-in-out 13s infinite;
}
.blob:nth-child(2) {
  width: 330px;
  height: 330px;
  right: 24%;
  bottom: -65%;

  animation: wobble 5s ease-in-out alternate infinite, blob-two ease-in-out 22s infinite;
}
.blob:nth-child(3) {
  width: 150px;
  height: 150px;
  bottom: -15%;
  left: 34%;

  animation: wobble 6s ease-in-out alternate infinite, blob-three ease-in-out 16s infinite;
}
.blob:nth-child(4) {
  width: 235px;
  height: 235px;
  bottom: -19%;
  left: 30%;

  animation: wobble 7s ease-in-out alternate infinite, blob-four ease-in-out 12s infinite;
}
.blob:nth-child(4) {
  width: 235px;
  height: 235px;
  bottom: -19%;
  left: 30%;
  animation: wobble 8s ease-in-out alternate infinite, blob-four ease-in-out 12s infinite;
}
.blob:nth-child(5) {
  width: 55px;
  height: 55px;
  bottom: -25%;
  left: 34%;

  animation: wobble 9s ease-in-out alternate infinite, blob-five ease-in-out 32s infinite;
}
.blob:nth-child(6) {
  width: 35px;
  height: 35px;
  bottom: -25%;
  right: 34%;

  animation: wobble 10s ease-in-out alternate infinite, blob-six ease-in-out 12s infinite;
}
.blob:nth-child(7) {
  width: 435px;
  height: 435px;
  bottom: -85%;
  right: 40%;

  animation: wobble 11s ease-in-out alternate infinite, blob-seven ease-in-out 32s infinite;
}
@keyframes blob-one {
  0%,
  100% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(-700%);
  }
}

@keyframes blob-two {
  0%,
  100% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(-420%);
  }
}

@keyframes blob-three {
  0%,
  100% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(-305%);
  }
}
@keyframes blob-four {
  0%,
  100% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(-605%);
  }
}
@keyframes blob-five {
  0%,
  100% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(-700%);
  }
}
@keyframes blob-six {
  0%,
  100% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(-700%);
  }
}
@keyframes blob-seven {
  0%,
  100% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(-300%);
  }
}

@keyframes wobble {
  50% {
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
  }
  100% {
    border-radius: 38% 52% 75% 36% / 50% 40% 50% 60%;
  }
}
