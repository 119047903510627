.btn-simple {
  --btn-simple-padding: 21px 48px;
  --btn-simple-text-size: 14px;
  --btn-simple-mt: 10px;
  --btn-simple-border-radius: 0px;
  --btn-simple-transition-in: 0.2s;
  --btn-simple-transition-out: 0.2s;
  --btn-simple-padding-xs: 4px 5px;
  --btn-simple-text-size-xs: 10px;
  --btn-simple-padding-sm: 6px 10px;
  --btn-simple-text-size-sm: 12px;
  --btn-simple-padding-lg: 10px 18px;
  --btn-simple-text-size-lg: 18px;
  --btn-group-separation: 18px;
}
//@include generate-btn-simple(var(--name, $color, $bg, $color-hover, $bg-hover, $bg-active, $color-disabled, $bg-disabled));

@include generate-btn-simple(1, white, var(--color-highlight), white, #444, #444, white, var(--color-disabled));
@include generate-btn-simple(2, white, var(--color-danger), white, #444, #444, white, var(--color-disabled));

@include generate-btn-simple(3, white, var(--color-info), white, #444, #444, white, var(--color-disabled));

html {
  scroll-behavior: smooth;
}

.btn-simple {
  font-family: Avenir;
  font-size: 18px;
  border-radius: 50px;
}

.big-blob {
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  background: red;
  clip-path: url(#svgClipPathID);
  @media (max-width: $screen-xs-max) {
    display: none;
  }
}

#big-blob-svg,
#small-blobs-svg {
  position: absolute;
}

.svg {
  position: absolute;
  width: 0;
  height: 0;
}
.hero {
  min-height: 60vh;
}
.big-blob {
  width: 70vw;
  height: 80%;
  background: linear-gradient(to bottom, var(--color-highlight), var(--color-bg-2));
  background-size: cover;
  clip-path: url(#big-blob);
  position: absolute;
  top: 0;
  right: 0;
  animation: move 10s ease-in-out infinite;
  translate: 20% -20%;
  z-index: -1;
  pointer-events: none;
  @media (max-width: $screen-xs-max) {
    width: 150vw;
  }
}

@keyframes move {
  0% {
    transform: scale(1) translate(100px 100px);
  }
  38% {
    transform: scale(1, 1.2) rotate(30deg);
  }
  40% {
    transform: scale(1, 1.2) rotate(30deg);
  }
  78% {
    transform: scale(1.2) rotate(-18deg);
  }
  80% {
    transform: scale(1.2) rotate(-15deg);
  }
  100% {
    transform: scale(1);
  }
}

.is-highlight {
  color: var(--color-highlight);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  @media (max-width: $screen-xs-max) {
    -webkit-text-stroke-width: 0;
  }
}

.header {
  position: static;
  top: 0;
  width: 100%;
  z-index: 9;
  padding: 12px 0;
  margin-bottom: 50px;
  // background: rgba(255, 255, 255, 0.1);
  // backdrop-filter: blur(7px);
  &__logo {
    width: 250px;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__links {
    flex-basis: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__link {
    color: #fff;
    font-weight: bold;
  }
}

.hero {
  &__item {
    translate: 10% 0%;
    display: flex;
    height: 444px;
    position: relative;
    button {
      cursor: pointer;
    }
    svg {
      transition: 0.5s;
      height: 100px;
      width: 100px;
      fill: white;
      transform: rotate(180deg);
      stroke: var(--color-bg-2);
    }
  }
  .iframe-container {
    position: absolute;
    top: 60px;
    left: 338px;
  }
}

body {
  overflow-x: hidden;
  font-family: "Avenir";
}

.img,
.picture {
  width: 100%;
  max-width: 100%;
}

.container--center {
  text-align: center;
}

.rfm-marquee {
  img {
    height: 80px;
    margin-right: 50px;
  }
}

.path-0 {
  animation: pathAnim-0 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes pathAnim-0 {
  0% {
    d: path(
      "M 0,400 L 0,0 C 70.08656818962555,31.90656131913432 140.1731363792511,63.81312263826864 206,94 C 271.8268636207489,124.18687736173136 333.3940226726211,152.65407076605976 395,174 C 456.6059773273789,195.34592923394024 518.2507729302645,209.57059429749228 597,216 C 675.7492270697355,222.42940570250772 771.6028856063207,221.06355204397113 849,206 C 926.3971143936793,190.93644795602887 985.3376846444523,162.17519752662318 1052,166 C 1118.6623153555477,169.82480247337682 1193.0463758158708,206.23565784953627 1259,184 C 1324.9536241841292,161.76434215046373 1382.4768120920646,80.88217107523187 1440,0 L 1440,400 L 0,400 Z"
    );
  }
  25% {
    d: path(
      "M 0,400 L 0,0 C 72.55238749570594,27.38234283751288 145.1047749914119,54.76468567502576 212,96 C 278.8952250085881,137.23531432497424 340.13328753005845,192.32360013740984 406,186 C 471.86671246994155,179.67639986259016 542.3620748883546,111.94091377533492 605,100 C 667.6379251116454,88.05908622466508 722.4184129165235,131.91274476125042 787,166 C 851.5815870834765,200.08725523874958 925.9642734455515,224.40810717966335 996,216 C 1066.0357265544485,207.59189282033665 1131.724493301271,166.45482652009616 1205,125 C 1278.275506698729,83.54517347990382 1359.1377533493646,41.77258673995191 1440,0 L 1440,400 L 0,400 Z"
    );
  }
  50% {
    d: path(
      "M 0,400 L 0,0 C 57.13500515286843,100.95499828237719 114.27001030573686,201.90999656475438 177,212 C 239.72998969426314,222.09000343524562 308.054963929921,141.31501202335966 391,121 C 473.945036070079,100.68498797664034 571.510133974579,140.82995534180696 649,138 C 726.489866025421,135.17004465819304 783.9045001717625,89.36516660941255 847,89 C 910.0954998282375,88.63483339058745 978.8718653383714,133.7093782205428 1042,172 C 1105.1281346616286,210.2906217794572 1162.608038474751,241.79732050841633 1228,212 C 1293.391961525249,182.20267949158367 1366.6959807626245,91.10133974579183 1440,0 L 1440,400 L 0,400 Z"
    );
  }
  75% {
    d: path(
      "M 0,400 L 0,0 C 83.37890759189281,60.32566128478187 166.75781518378562,120.65132256956375 235,125 C 303.2421848162144,129.34867743043625 356.34764685675026,77.72037100652695 416,85 C 475.65235314324974,92.27962899347305 541.8515973892132,158.4671934043284 605,177 C 668.1484026107868,195.5328065956716 728.2459635863964,166.41085537615942 808,145 C 887.7540364136036,123.5891446238406 987.1645482652009,109.88938509103403 1063,138 C 1138.835451734799,166.11061490896597 1191.0958433527999,236.03160425970455 1250,220 C 1308.9041566472001,203.96839574029545 1374.4520783236,101.98419787014772 1440,0 L 1440,400 L 0,400 Z"
    );
  }
  100% {
    d: path(
      "M 0,400 L 0,0 C 70.08656818962555,31.90656131913432 140.1731363792511,63.81312263826864 206,94 C 271.8268636207489,124.18687736173136 333.3940226726211,152.65407076605976 395,174 C 456.6059773273789,195.34592923394024 518.2507729302645,209.57059429749228 597,216 C 675.7492270697355,222.42940570250772 771.6028856063207,221.06355204397113 849,206 C 926.3971143936793,190.93644795602887 985.3376846444523,162.17519752662318 1052,166 C 1118.6623153555477,169.82480247337682 1193.0463758158708,206.23565784953627 1259,184 C 1324.9536241841292,161.76434215046373 1382.4768120920646,80.88217107523187 1440,0 L 1440,400 L 0,400 Z"
    );
  }
}

.path-1 {
  animation: pathAnim-1 40s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes pathAnim-1 {
  0% {
    d: path(
      "M 0,400 L 0,0 C 109.7129186602871,13.559808612440193 219.4258373205742,27.119617224880386 329,80 C 438.5741626794258,132.8803827751196 548.0095693779905,225.08133971291866 620,223 C 691.9904306220095,220.91866028708134 726.5358851674641,124.55502392344499 822,121 C 917.4641148325359,117.44497607655501 1073.8468899521529,206.69856459330143 1187,202 C 1300.1531100478471,197.30143540669857 1370.0765550239234,98.65071770334929 1440,0 L 1440,400 L 0,400 Z"
    );
  }
  25% {
    d: path(
      "M 0,400 L 0,0 C 78.11483253588514,42.44019138755981 156.22966507177028,84.88038277511961 244,109 C 331.7703349282297,133.1196172248804 429.1961722488039,138.91866028708134 525,159 C 620.8038277511961,179.08133971291866 714.9856459330142,213.44497607655504 810,198 C 905.0143540669858,182.55502392344496 1000.8612440191389,117.30143540669856 1106,76 C 1211.138755980861,34.69856459330144 1325.5693779904304,17.34928229665072 1440,0 L 1440,400 L 0,400 Z"
    );
  }
  50% {
    d: path(
      "M 0,400 L 0,0 C 116.71770334928232,56.631578947368425 233.43540669856463,113.26315789473685 330,131 C 426.56459330143537,148.73684210526315 502.97607655502384,127.57894736842105 598,116 C 693.0239234449762,104.42105263157895 806.6602870813398,102.42105263157895 891,101 C 975.3397129186602,99.57894736842105 1030.3827751196172,98.73684210526316 1117,82 C 1203.6172248803828,65.26315789473684 1321.8086124401914,32.63157894736842 1440,0 L 1440,400 L 0,400 Z"
    );
  }
  75% {
    d: path(
      "M 0,400 L 0,0 C 66.13397129186606,79.11004784688996 132.26794258373212,158.2200956937799 247,185 C 361.7320574162679,211.7799043062201 525.0622009569377,186.22966507177034 622,153 C 718.9377990430623,119.77033492822967 749.4832535885168,78.86124401913877 824,92 C 898.5167464114832,105.13875598086123 1017.0047846889952,172.32535885167465 1127,166 C 1236.9952153110048,159.67464114832535 1338.4976076555024,79.83732057416267 1440,0 L 1440,400 L 0,400 Z"
    );
  }
  100% {
    d: path(
      "M 0,400 L 0,0 C 109.7129186602871,13.559808612440193 219.4258373205742,27.119617224880386 329,80 C 438.5741626794258,132.8803827751196 548.0095693779905,225.08133971291866 620,223 C 691.9904306220095,220.91866028708134 726.5358851674641,124.55502392344499 822,121 C 917.4641148325359,117.44497607655501 1073.8468899521529,206.69856459330143 1187,202 C 1300.1531100478471,197.30143540669857 1370.0765550239234,98.65071770334929 1440,0 L 1440,400 L 0,400 Z"
    );
  }
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  margin-bottom: 20px;
}

/* Then style the iframe to fit in the container div with full height and width */
.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.showcase {
  .showcase__video {
    position: absolute;
    // width: 43vw;
    // height: 24vw;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    top: 0;
    @media (min-width: $screen-xs) {
      width: 40%;
      height: 50%;
    }
    @media (min-width: $screen-sm) {
      width: 340px;
      height: 190px;
    }
    @media (min-width: $screen-md) {
      width: 460px;
      height: 260px;
    }
    @media (min-width: $screen-lg) {
      width: 550px;
      height: 300px;
    }
    @media (min-width: $screen-xl) {
      width: 600px;
      height: 340px;
    }
  }
}

@function randomNum($min, $max) {
  $rand: random();
  $randomNum: $min + floor($rand * (($max - $min) + 1));

  @return $randomNum;
}
.blobs {
  min-height: 600px;
  position: relative;
}
.top-bloby,
.bottom-bloby {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
  background: var(--color-highlight);
  border-radius: 0 0 100% 100%;
  animation: height #{randomNum(10, 20)}s ease-in-out infinite;
}
.bottom-bloby {
  top: auto;
  bottom: 0;
  border-radius: 100% 100% 0 0;
}
.bloby {
  position: absolute;
  top: 0;
  left: 0;
  fill: var(--color-highlight);
  width: #{randomNum(5, 10)}vw;
  z-index: -1;
  animation: movy 20s ease-in-out infinite;
  transform-origin: 50% 50%;
  transform: translate(5vw, -12vh);
  &:nth-child(1) {
    left: 5%;
    width: #{randomNum(10, 24)}vw;
    animation: movy #{randomNum(10, 20)}s ease-in-out infinite;
  }
  &:nth-child(2) {
    left: 25%;
    width: #{randomNum(4, 24)}vw;
    animation: movy2 #{randomNum(10, 20)}s ease-in-out infinite;
  }
  &:nth-child(3) {
    left: 35%;
    width: #{randomNum(5, 24)}vw;
    animation: movy3 #{randomNum(10, 20)}s ease-in-out infinite;
  }
  &:nth-child(4) {
    left: 45%;
    width: #{randomNum(5, 24)}vw;
    animation: movy2 #{randomNum(10, 20)}s ease-in-out infinite;
  }
  &:nth-child(5) {
    left: 55%;
    width: #{randomNum(5, 24)}vw;
    animation: movy #{randomNum(10, 20)}s ease-in-out infinite;
  }
  &:nth-child(6) {
    left: 65%;
    width: #{randomNum(6, 24)}vw;
    animation: movy3 #{randomNum(10, 20)}s ease-in-out infinite;
  }
  &:nth-child(7) {
    left: 75%;
    width: #{randomNum(2, 24)}vw;
    animation: movy2 #{randomNum(10, 20)}s ease-in-out infinite;
  }
  &:nth-child(8) {
    left: 85%;
    width: #{randomNum(1, 24)}vw;
    animation: movy #{randomNum(10, 20)}s ease-in-out infinite;
  }
  &:nth-child(9) {
    left: 95%;
    width: #{randomNum(2, 24)}vw;
    animation: movy3 #{randomNum(10, 20)}s ease-in-out infinite;
  }
}
$top: -10vw;
$bottom: 30vw;

@keyframes height {
  0% {
    height: 10%;
  }

  58% {
    height: 15px;
  }

  100% {
    height: 10%;
  }
}

@keyframes movy {
  0% {
    transform: scale(1) translate(0, $bottom) rotate(12deg);
  }
  38% {
    transform: scale(1.4) translate(0, $top) rotate(#{randomNum(1, 45)}deg);
  }
  58% {
    transform: scale(1) translate(0, $top) rotate(#{randomNum(1, 45)}deg);
  }
  80% {
    transform: scale(1.4) translate(0, $top) rotate(#{randomNum(1, 45)}deg);
  }
  100% {
    transform: scale(1) translate(0, $bottom) rotate(12deg);
  }
}

@keyframes movy2 {
  0% {
    transform: scale(1) translate(0, $bottom) rotate(12deg);
  }
  38% {
    transform: scale(1.4) translate(0, $top) rotate(#{randomNum(1, 45)}deg);
  }
  58% {
    transform: scale(1) translate(0, $top) rotate(#{randomNum(1, 45)}deg);
  }
  80% {
    transform: scale(1.4) translate(0, $top) rotate(#{randomNum(1, 45)}deg);
  }
  100% {
    transform: scale(1) translate(0, $bottom) rotate(12deg);
  }
}
@keyframes movy3 {
  0% {
    transform: scale(1) translate(0, $bottom) rotate(12deg);
  }
  38% {
    transform: scale(1.4) translate(0, $top) rotate(#{randomNum(1, 45)}deg);
  }
  58% {
    transform: scale(1) translate(0, $top) rotate(#{randomNum(1, 45)}deg);
  }
  80% {
    transform: scale(1.4) translate(0, $top) rotate(#{randomNum(1, 45)}deg);
  }
  100% {
    transform: scale(1) translate(0, $bottom) rotate(12deg);
  }
}

.download-links {
  display: flex;
  justify-content: space-around;
  margin-bottom: 70px;
  @media (max-width: $screen-xs-max) {
    display: block;
  }
  a {
    margin-bottom: 12px;
  }
  img {
    width: 100px;
  }
  li {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    @media (max-width: $screen-xs-max) {
      margin-bottom: 64px;
    }
  }
}

svg.logo .path {
  stroke-dasharray: 1320;
  stroke-dashoffset: 0;
  fill: #fff;
  animation: dash 60s linear alternate infinite;
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1320;
    fill: #fff;
  }
  3% {
    stroke-dashoffset: 0;
    stroke-dasharray: 1320;
    fill: #fff;
  }
  5% {
    stroke-dashoffset: 0;
    stroke-dasharray: 1320;

    fill: inherit;
  }
  10% {
    stroke-dashoffset: 0;
    stroke-dasharray: 1320;

    fill: inherit;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 1320;

    fill: inherit;
  }
}

.text-line {
  stroke-dasharray: 500;
  stroke-dashoffset: 500;
  animation: dashfont 5s linear forwards, filling 5s ease-in forwards;
  font-size: 80px;
}

@keyframes dashfont {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes filling {
  0%,
  90% {
    fill: #bac736;
    fill-opacity: 0;
  }
  100% {
    fill: #bac736;
    fill-opacity: 1;
  }
}
