@import "~getbasecore/src/utils/vars/core_vars.scss";
:root {
  /*
Margin Scale
*/
  --margin1: 0.8rem;
  --margin2: 1.6rem;
  --margin3: 2.4rem;
  --margin4: 3.2rem;
  --margin5: 4rem;
  --margin6: 4.8rem;

  --margin-elements: 32px;
  --margin-components: 18rem;

  // --------------------------------------------------
  //  Icons sizes
  // --------------------------------------------------
  --icon-xl: 128px;
  --icon-lg: 56px;
  --icon-md: 48px;
  --icon-sm: 32px;
  --icon-xs: 24px;

  // --------------------------------------------------
  //  Inputs & Buttons fixed height inside forms
  // --------------------------------------------------
  --input-height-xs: 56px;
  --input-height-sm: 56px;
  --input-height: 56px;
  --input-height-lg: 56px;
}
// --------------------------------------------------
// Colors - Global Palette
// --------------------------------------------------

:root {
  --color-success: #99cc33;
  --color-info: #6ea0c6;
  --color-warning: #fb7d0c;
  --color-danger: #ec3457;
  --color-disabled: #afafaf;

  --color-text-1: #262626;
  --color-text-2: #444;

  --white: #ffffff;
  --anthracite: #262626;
  --black: #000000;
  --grey1: #e6e6e6;
  --grey2: #666666;
  --grey3: #bbbbbb;
  --blue1: #1c69d4;
  --blue2: #0653b6;
  --brown-grey: #979797;

  --color-bg-primary: #1c69d4;

  --color-text-1: #444;
  --color-text-2: #222;
  --color-text-3: #222;
  --color-text-4: #666666;

  //  BG colors
  --color-bg-1: #ac30d3;
  --color-bg-2: #09263c;
  --color-bg-3: #99cc33;
  --color-bg-4: #0076d9;
  --color-bg-5: rgb(251, 225, 109);
  --color-bg-6: #c41772;
  --color-bg-7: #e6e6e6;
  --color-border-1: #e3e3e3;

  --color-black: #262626;
  --color-brown-grey: #8e8e8e;
  --color-blue-blue: #1c69d4;
  --color-greyish-brown: #4d4d4d;
  --color-very-light-pink: #d8d8d8;

  --color-placeholder-1: #e3e3e3;

  --color-accordion--hover: #f5f5f5;
  --color-brand: rgb(0, 118, 217);

  --color-highlight: var(--color-bg-1);
}
.steamOS {
  --color-highlight: var(--color-bg-1);
}

.Windows {
  --color-highlight: var(--color-bg-4);
}

.Android {
  --color-highlight: var(--color-bg-3);
}
